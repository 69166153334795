import React from "react";

import { styled } from "@styles/theme";
import LoginImage from "@icons/illustrations/login-image";

import { Message } from "@components/common/Message";
import { Spacer } from "@components/common/Spacer";
import { Box, Flex } from "@components/common/ResponsiveBox";
import { SignInForm } from "@components/pages/login/LoginForm";
import Header from "@components/common/Header/HeaderUnlogged";
import { H1 } from "@components/common/Headings";
import Grid from "@components/common/Grid2";
import Media from "@components/common/Media";

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  background: linear-gradient(266.65deg, #edf2f7 22%, #ffffff 48%);
`;

const Welcome = () => (
  <Flex display="inline-flex" textAlign="start" wrap="nowrap">
    <H1>Login in to your account</H1>
  </Flex>
);

const MESSAGES = {
  PASS_SENT: "If you have an account, we'll email you a reset link.",
  PASS_UPDATED: "Your password was changed successfully",
};

interface Props {
  message?: keyof typeof MESSAGES;
  accentureLoginUrl?: string;
}

export const LoginForm: React.FC<Props> = ({
  message,
  accentureLoginUrl = "/login/sso",
}) => {
  return (
    <>
      <Welcome />
      {!message && <Spacer size="xxg" />}
      {message && (
        <>
          <Spacer size="xxg" />
          <Message color="green" mB="xxxg">
            {MESSAGES[message]}
          </Message>
        </>
      )}
      <SignInForm accentureLoginUrl={accentureLoginUrl} />
    </>
  );
};

const Login: React.FC<Props> = ({ message }) => {
  return (
    <Container>
      <Flex
        mX="auto"
        maxW={1200}
        mT={{ 0: "md", 2: "xxxg" }}
        pX={{ 0: "md", 3: "none" }}
      >
        <Header dark_logo has_login_button />

        <Grid
          mT={["lg", "xhg"]}
          width="100%"
          templateCols={["1fr ", "1fr ", "5fr 1fr 6fr"]}
          alignItems="center"
          gapX="xg"
        >
          <Box>
            <LoginForm message={message} />
          </Box>

          <Box />

          <Media min="md">
            <LoginImage />
          </Media>
        </Grid>
      </Flex>
    </Container>
  );
};

// @ts-ignore
Login.getInitialProps = (ctx) => {
  const { message } = ctx.query;
  return { message };
};

// @ts-ignore
Login.public = true;

export default Login;
