import React from "react";
import invariant from "ts-invariant";
import facepaint, { DynamicStyleFunction } from "facepaint";

import { styled, Theme, theme } from "../../styles/theme";

const { breakpoints } = theme;

type Props = {
  max?: number | keyof Theme["breakpoints"];
  min?: number | keyof Theme["breakpoints"];
};

const Wrapper = styled.div<{ mq: DynamicStyleFunction }>`
  ${(p) =>
    p.mq({ display: ["none", "inherit"], visibility: ["hidden", "inherit"] })};
`;

/**
 * For now it can handle just one query at a time.
 * We can improve these props over time and necessity
 */
const Media: React.FC<Props> = ({ children, max, min }) => {
  const getMediaQuery = (): { width: number; media: string } => {
    if (max) {
      return {
        media: "max-width",
        width: (typeof max === "string" ? breakpoints[max] : max) - 1,
      };
    }

    if (min) {
      return {
        media: "min-width",
        width: typeof min === "string" ? breakpoints[min] : min,
      };
    }

    invariant(
      false,
      "You must provide at least one selector for the Media component",
    );

    return null as never;
  };

  const { media, width } = getMediaQuery();
  const mq = facepaint([`@media(${media}: ${width}px)`], { overlap: true });

  return <Wrapper mq={mq}>{children}</Wrapper>;
};

export default Media;
