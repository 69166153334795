import { CSSProperties } from "react";

import {
  formatProp,
  Responsive,
  ResponsiveProps,
  responsiveStyling,
} from "@styles/responsive";
import { styled, Theme } from "@styles/theme";

type AditionalStyles = {
  responsiveStyles?: {
    [k in keyof CSSProperties]: Responsive<CSSProperties[k]>;
  };
};
interface Props extends ResponsiveProps, AditionalStyles {
  alignItems?: Responsive<CSSProperties["alignItems"]>;
  justify?: Responsive<CSSProperties["justifyContent"]>;
  round?: "xxs" | "md" | "lg" | "xl" | "full" | boolean;
  shadow?: keyof Theme["shadow"];
  cursor?: CSSProperties["cursor"];
}

const Grid = styled.div<Props>`
  display: grid;
  cursor: ${(p) => p.cursor || "inherit"};
  box-shadow: ${(p) => (p.shadow ? p.theme.shadow[p.shadow] : "initial")};
  border-radius: ${(p) =>
    p.round === true
      ? p.theme.spacing.xxs
      : p.round === "full"
      ? "100%"
      : p?.round
      ? p.theme.spacing[(p.round as keyof Theme["spacing"]) || "xxs"]
      : "none"};

  ${(p) => {
    return responsiveStyling({
      gridTemplate: p.gridTemplate,
      gridTemplateColumns: p.templateCols,
      alignItems: formatProp<CSSProperties["alignItems"]>(
        p.alignItems,
        "stretch",
      ),
      justify: formatProp<CSSProperties["justifyContent"]>(
        p.justify,
        "flex-start",
      ),
      ...p.responsiveStyles,
    });
  }}
`;

export default Grid;
